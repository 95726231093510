import React, { useState } from 'react'
import { useAsync } from 'react-async'
import queryString from 'query-string'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Col,
  Empty,
  notification,
  Row,
  Popconfirm,
  Table,
  Typography,
} from 'antd'
import compact from 'lodash.compact'
import flatten from 'lodash.flatten'
import orderBy from 'lodash/orderBy'
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DownloadOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import {
  batchUpdateContainersName,
  bulkActivate,
  bulkDeactivate,
  bulkAddContainer,
  createContainer,
  deleteContainer,
  exportContainers,
  getContainers,
  getCountries,
  updateContainer,
} from './api'
import { getContainerTypes } from '../container-types/api'
import { useAuth0 } from '../../auth0'
import { Can, ErrorMessage } from '../../components'
import { CONTAINER_STATUSES } from '../../constants'
import { getTableHeaderCheckboxFilter, getTableHeaderSearchInput } from '../../utils'
import ContainersModalForm from './ContainersModal'
import ContainerBulkUpdateModal from './ContainerBulkUpdateModal'
import ContainerBulkAddModal from './ContainerBulkAddModal'
import BatchNameBulkUpdateModal from './BatchNameBulkUpdateModal'
import ContainerUploader from './ContainerUploader'
import csvSample from '../../assets/templates/import_containers.csv'
import './ContainersPage.css'

const { Title } = Typography

const convertToFlatArray = (variable) => compact(flatten([variable]))

const ContainerPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isContainerModalVisible, setContainerModalVisibility] =
    useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [isBulkContainerModalVisible, setBulkContainerModalVisibility] =
    useState(false)
  const [isBulkAddContainerModalVisible, setBulkAddContainerModalVisibility] =
    useState(false)
  const [isBatchNameModalVisible, setBatchNameModalVisibility] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [selectedContainer, setSelectedContainer] = useState(null)
  const queryParams = queryString.parse(location.search)
  const itemsPerPage = parseInt(queryParams.items_per_page, 10) || 25
  const page = parseInt(queryParams.page, 10) || 1
  const idFilter = convertToFlatArray(queryParams.id)
  const identityCodeFilter = convertToFlatArray(queryParams.identity_code)
  const statusFilter = convertToFlatArray(queryParams.status)
  const requestPackageFilter = convertToFlatArray(queryParams.request_package)
  const countryFilter = convertToFlatArray(queryParams.country)
  const containerTypeFilter = convertToFlatArray(queryParams.type)
  const filter = {
    container_type_id: queryParams.type,
    country: queryParams.country,
    identity_code: queryParams.identity_code,
    request_package: queryParams.request_package,
  }
  const isBulkUpdateAvailabe =
    queryParams.status === 'inactive' || queryParams.status === 'ready_to_use'
  const isBulkBatchNameUpdateAvailable = !!queryParams.request_package
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    country: queryParams.country,
    id: queryParams.id,
    identity_code: queryParams.identity_code,
    itemsPerPage,
    page,
    promiseFn: getContainers,
    request_package: queryParams.request_package,
    status: queryParams.status,
    type: queryParams.type,
    watch: location.search,
  })

  const exportContainersCsv = () => {
    const params = {
      accessTokenPromise,
      country: queryParams.country,
      id: queryParams.id,
      identity_code: queryParams.identity_code,
      request_package: queryParams.request_package,
      status: queryParams.status,
      type: queryParams.type,
    }

    let originalFileName

    exportContainers(params)
      .then((response) => {
        originalFileName = response.headers.get('content-disposition')
        return response.blob()
      })
      .then((res) => {
        const fileName = originalFileName.replace('attachment; filename*=UTF-8\'\'', '')

        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)

        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)

        notification.open({
          description: 'Containers are exported successfully.',
          duration: 3,
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          message: 'Success',
        })
      })
      .catch(() => {
        notification.open({
          description: 'Containers export failed.',
          duration: 3,
          icon: <CloseCircleTwoTone twoToneColor="#f5222d" />,
          message: 'Failure',
        })
      })
  }

  const handleDownload = () => {
    setTimeout(() =>
      notification.open({
        description: 'Sample downloaded successfully.',
        duration: 3,
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        message: 'Success',
      }),
    1000)
  }

  const containerTypesData = useAsync({
    accessTokenPromise,
    promiseFn: getContainerTypes,
  })

  const countriesData = useAsync({
    accessTokenPromise,
    promiseFn: getCountries,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  const containerTypes = containerTypesData?.data?.container_types
    ? orderBy(containerTypesData.data.container_types, 'name', 'asc')
    : []
  const countries = countriesData?.data?.sort() ?? []

  const handleSuccess = (messageOptions = {}, isDelete = false) => {
    if (isDelete) {
      navigate('/containers?page=1')
    } else {
      reload()
    }

    setContainerModalVisibility(false)
    setBulkContainerModalVisibility(false)
    setBulkAddContainerModalVisibility(false)
    setBatchNameModalVisibility(false)
    setErrorMessage(null)

    notification.open({
      description: 'Container is added successfully',
      duration: 3,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    console.log(error)
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Container creation is failed.',
      duration: 3,
      icon: <CloseCircleTwoTone twoToneColor="#f5222d" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'ACTIVATE':
      return bulkActivate({ accessTokenPromise, fields: filter })
        .then(() =>
          handleSuccess({
            description: 'Container is activated successfully.',
          }),
        )
        .catch((error) =>
          handleFailure(error, {
            description: 'Container activate is failed.',
          }),
        )

    case 'BULK_ADD':
      return bulkAddContainer({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess())
        .catch(handleFailure)

    case 'DEACTIVATE':
      return bulkDeactivate({ accessTokenPromise, fields: filter })
        .then(() =>
          handleSuccess({
            description: 'Container is deactivated successfully.',
          }),
        )
        .catch((error) =>
          handleFailure(error, {
            description: 'Container deactivate is failed.',
          }),
        )

    case 'CREATE':
      return createContainer({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess())
        .catch(handleFailure)

    case 'UPDATE':
      return updateContainer({ accessTokenPromise, fields: payload })
        .then(() =>
          handleSuccess({ description: 'Container is updated successfully.' }),
        )
        .catch((error) =>
          handleFailure(error, { description: 'Container update is failed.' }),
        )

    case 'BATCH_UPDATE':
      return batchUpdateContainersName({ accessTokenPromise, fields: payload, requestPackageFilter })
        .then(() =>
          handleSuccess({ description: 'Names are updated successfully.' }),
        )
        .catch((error) =>
          handleFailure(error, { description: 'Name update is failed.' }),
        )

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleOpenModal = ({ selectedContainer, setModalToVisible }) => {
    setSelectedContainer(selectedContainer)
    setErrorMessage(null)
    setModalToVisible(true)
    setModalLoading(false)
  }

  const handleTableChange = (pagination, filters, customFilters) => {
    const queryStringObject = {
      ...filters,
      items_per_page: pagination.pageSize,
      page: pagination.current,
    }

    navigate({
      ...location,
      search: queryString.stringify(queryStringObject),
    })
  }

  const openNotificationWithIcon = (desc) =>
    notification.info({
      description: desc,
      duration: 0,
      message: 'Please note',
    })

  const handleDeleteContainer = (recordId) => {
    deleteContainer({ accessTokenPromise, recordId })
      .then(() =>
        handleSuccess(
          { description: 'Container is removed successfully.' },
          true,
        ),
      )
      .catch((error) =>
        handleFailure(error, { description: 'Container removal is failed.' }),
      )
  }

  const totalItems = (data && data.pagination_meta.total_items) || 0
  const containers = (data && data.containers) || []
  const containersName = (data && data.containers.length > 1 && data.containers[0].request_package)
  const typeFilter = containerTypes.map((container) => ({
    text: container.name,
    value: container.id,
  }))
  const countryFilters = countries.map((country) => ({ text: country, value: country }))
  const statusFilters = CONTAINER_STATUSES.sort().map((status) => ({
    text: status,
    value: status,
  }))

  const columns = [
    {
      className: 'idSelenium',
      dataIndex: 'id',
      filteredValue: idFilter,
      key: 'id',
      title: 'ID',
      width: 100,
    },
    {
      className: 'typeSelenium',
      dataIndex: 'type',
      filteredValue: containerTypeFilter,
      render: (_, record) => record.type.name,
      title: 'Container type',
      ...getTableHeaderCheckboxFilter('typeFilterSelenium', typeFilter),
    },
    {
      className: 'countrySelenium',
      dataIndex: 'country',
      filteredValue: countryFilter,
      key: 'country',
      title: 'Country',
      ...getTableHeaderCheckboxFilter('countryFilterSelenium', countryFilters),
    },
    {
      className: 'identityCodeSelenium',
      dataIndex: 'identity_code',
      filteredValue: identityCodeFilter,
      key: 'identity_code',
      render: (_, record) => (
        <Link to={`/containers/${record.id}`}>{record.identity_code}</Link>
      ),
      title: 'Identity code',
      ...getTableHeaderSearchInput('identityCodeSearchSelenium'),
    },
    {
      className: 'statusSelenium',
      dataIndex: 'status',
      filteredValue: statusFilter,
      filterMultiple: false,
      key: 'status',
      title: 'Current status',
      ...getTableHeaderCheckboxFilter('statusFilterSelenium', statusFilters),
    },
    {
      className: 'numberOfCyclesSelenium',
      dataIndex: 'number_of_cycles',
      key: 'number_of_cycles',
      title: 'Number of cycles',
    },
    {
      className: 'batchNameSelenium',
      dataIndex: 'request_package',
      filteredValue: requestPackageFilter,
      key: 'request_package',
      title: 'Batch Name',
      ...getTableHeaderSearchInput('batchNameSearchSelenium'),
    },
    {
      className: 'actionSelenium',
      key: 'action',
      render: (record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:containers"
            yes={() => (
              <Button
                className="change-status-button"
                disabled={record.status === 'deactivated'}
                name="changeContainerStatusSelenium"
                onClick={() =>
                  handleOpenModal({
                    selectedContainer: record,
                    setModalToVisible: setContainerModalVisibility,
                  })
                }
                size="small"
              >
                Change status
              </Button>
            )}
          />
          <Can
            requiredPermission="delete:containers"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="delete-popconfirm"
                disabled={record.status !== 'inactive'}
                okText="Yes"
                onConfirm={() => handleDeleteContainer(record.id)}
                title="Are you sure you want to delete this container?"
              >
                <Button
                  className="action-button delete-container-button"
                  danger
                  disabled={record.status !== 'inactive'}
                  ghost
                  name="deleteContainerSelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
      width: 100,
    },
  ]

  return (
    <div>
      <Title>Containers</Title>
      <Row>
        <Col span={12} style={{ marginBottom: '20px', textAlign: 'left' }}>
          <Row>
            <Can
              requiredPermission="read:containers"
              yes={() => (
                <Button
                  name="exportContainerSelenium"
                  onClick={exportContainersCsv}
                >
                  Export CSV
                </Button>
              )}
            />

            <Can
              requiredPermission="create:containers"
              yes={() => <ContainerUploader />}
            />

            <a
              className="download-link"
              download
              href={csvSample}
              onClick={handleDownload}
              style={{ height: '32px', lineHeight: '32px', marginLeft: '20px' }}
            >
              Download Sample&nbsp;
              <DownloadOutlined />
            </a>
          </Row>
        </Col>
        <Col span={12} style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:containers"
            yes={() => (
              <Button
                name="createContainerSelenium"
                onClick={() =>
                  handleOpenModal({
                    selectedContainer: null,
                    setModalToVisible: setContainerModalVisibility,
                  })
                }
                type="primary"
              >
                Add
              </Button>
            )}
          />

          <Can
            requiredPermission="create:containers"
            yes={() => (
              <Button
                name="createContainerInBulkSelenium"
                onClick={() =>
                  handleOpenModal({
                    setModalToVisible: setBulkAddContainerModalVisibility,
                  })
                }
                style={{ marginLeft: '20px' }}
                type="primary"
              >
                Add containers in bulk
              </Button>
            )}
          />

          <Can
            requiredPermission="update:containers"
            yes={() => (
              <div style={{ display:'inline', marginTop: '20px' }}>
                <Button
                  disabled={
                    isLoading ||
                    containers.length === 0 ||
                    !isBulkUpdateAvailabe
                  }
                  name="changeContainerStatusInBulkSelenium"
                  onClick={() =>
                    handleOpenModal({
                      setModalToVisible: setBulkContainerModalVisibility,
                    })
                  }
                  style={{ marginLeft: '20px' }}
                  type="primary"
                >
                  Change status in bulk
                </Button>
                <InfoCircleOutlined
                  name="updateInfoIconSelenium"
                  onClick={() => openNotificationWithIcon(
                    'To use this feature, you should set the current status filter' +
                    'either to inactive or to ready to use.',
                  )}
                  style={{ marginLeft: '5px' }}
                />
              </div>
            )}
          />

          <Can
            requiredPermission="update:containers"
            yes={() => (
              <div style={{ marginTop: '20px' }}>
                <Button
                  disabled={
                    isLoading ||
                    containers.length === 0 ||
                    !isBulkBatchNameUpdateAvailable
                  }
                  name="changeBatchNameInBulkSelenium"
                  onClick={() =>
                    handleOpenModal({
                      setModalToVisible: setBatchNameModalVisibility,
                    })
                  }
                  style={{ marginLeft: '20px' }}
                  type="primary"
                >
                  Change batch name in bulk
                </Button>
                <InfoCircleOutlined
                  name="updateInfoIconSelenium"
                  onClick={() => openNotificationWithIcon(
                    'To use this feature, you should filter the Batch Name.',
                  )}
                  style={{ marginLeft: '5px' }}
                />
              </div>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            bordered
            className="containersTableSelenium"
            columns={columns}
            dataSource={containers}
            loading={isLoading}
            locale={{ emptyText: <Empty description="No containers" /> }}
            onChange={handleTableChange}
            pagination={{
              current: page,
              pageSize: itemsPerPage,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} Containers`,
              total: totalItems,
            }}
            rowKey="id"
            style={{ marginTop: '20px' }}
            width="auto"
          />
        </Col>
      </Row>

      {isContainerModalVisible && (
        <ContainersModalForm
          containerTypeList={containerTypes}
          countriesList={countries}
          errorMessage={errorMessage}
          hideModal={() => setContainerModalVisibility(false)}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedContainer={selectedContainer}
          setModalLoading={setModalLoading}
          setSelectedContainer={setSelectedContainer}
          setVisibility={setContainerModalVisibility}
        />
      )}

      {isBulkContainerModalVisible && (
        <ContainerBulkUpdateModal
          errorMessage={errorMessage}
          hideModal={() => setBulkContainerModalVisibility(false)}
          onSubmit={handleFormSubmit}
          setVisibility={setBulkContainerModalVisibility}
          status={queryParams.status}
          sumSelectedContainers={totalItems}
        />
      )}

      {isBulkAddContainerModalVisible && (
        <ContainerBulkAddModal
          containerTypeList={containerTypes}
          countriesList={countries}
          errorMessage={errorMessage}
          hideModal={() => setBulkAddContainerModalVisibility(false)}
          onSubmit={handleFormSubmit}
          setVisibility={setBulkAddContainerModalVisibility}
        />
      )}

      {isBatchNameModalVisible && (
        <BatchNameBulkUpdateModal
          errorMessage={errorMessage}
          hideModal={() => setBatchNameModalVisibility(false)}
          name={containersName}
          onSubmit={handleFormSubmit}
          setVisibility={setBatchNameModalVisibility}
          sumSelectedContainers={totalItems}
        />
      )}
    </div>
  )
}

export default ContainerPage
