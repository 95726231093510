import React from 'react'
import { useAsync } from 'react-async'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Layout } from 'antd'
import {
  ContainerDetailsPage,
  ContainersPage,
  ContainerTypesPage,
  Error404Page,
  HomePage,
} from './pages'
import {
  AuthenticationLayer,
  ErrorMessage,
  Profile,
  Sidebar,
} from './components'
import { getConfigs } from './api'
import { Auth0Provider } from './auth0'

const onRedirectCallback = appState => {
  // Temporary Firefox workaround
  window.location.hash = window.location.hash // eslint-disable-line no-self-assign

  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

const App = () => {
  const { data, error, isLoading } = useAsync({
    promiseFn: getConfigs,
  })

  const { Content, Header, Footer } = Layout

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />
  if (isLoading) return null

  return (
    <Auth0Provider
      authorizationParams={{
        audience: data?.audience,
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      clientId={data?.clientId}
      domain={data?.domain}
      onRedirectCallback={onRedirectCallback}
    >
      <Router>
        <AuthenticationLayer>
          <Layout style={{ minHeight: '100vh' }}>
            <Sidebar />
            <Layout>
              <Header style={{ background: '#fff', padding: '0 16px' }}>
                <Profile />
              </Header>
              <Content>
                <div style={{ background: '#fff', minHeight: 360, padding: 24 }}>
                  <Routes>
                    <Route element={<HomePage />} path="/" />
                    <Route element={<ContainersPage />} path="/containers" />
                    <Route element={<ContainerDetailsPage />} path="/containers/:id" />
                    <Route element={<ContainerTypesPage />} path="/container-types" />
                    <Route element={<Error404Page />} path="*" />
                  </Routes>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                Loop CoMS {new Date().getFullYear()}
              </Footer>
            </Layout>
          </Layout>
        </AuthenticationLayer>
      </Router>
    </Auth0Provider>
  )
}

export default App
