import React from 'react'
import { useAsync } from 'react-async'
import { useParams } from 'react-router-dom'
import {
  Col,
  Descriptions,
  Empty,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd'
import { getContainer, getContainerEvents } from './api'
import { useAuth0 } from '../../auth0'
import { ErrorMessage } from '../../components'
import { localizedDate } from '../../utils'
import { routePropTypes } from '../../types'

const { Title } = Typography

const ContainerDetailsPage = () => {
  const { id: containerId } = useParams()
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()
  const { data, error, isLoading } = useAsync({
    accessTokenPromise,
    containerId,
    promiseFn: getContainer,
  })

  const containerEvents = useAsync({
    accessTokenPromise,
    containerId,
    promiseFn: getContainerEvents,
  })

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  if (isLoading) return (
    <Spin name="loadingSpinnerSelenium" tip="Loading...">
      <div className="content" />
    </Spin>
  )

  const tableColumns = [
    {
      className: 'idSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      className: 'statusChangeAtSelenium',
      dataIndex: 'status_change_at',
      key: 'status_change_at',
      render: status_change_at => <span>{localizedDate(status_change_at)}</span>,
      title: 'Status changed at',
    },
    {
      className: 'statusFromSelenium',
      dataIndex: 'status_from',
      key: 'status_from',
      title: 'Status from',
    },
    {
      className: 'statusToSelenium',
      dataIndex: 'status_to',
      key: 'status_to',
      title: 'Status to',
    },
  ]

  return (
    <div>
      <Title>
        Container Details
      </Title>

      <Descriptions
        bordered
        className="containerDetailsTable"
        column={2}
        size="middle"
      >
        <Descriptions.Item label="ID" span={2}>
          {data.id}
        </Descriptions.Item>

        <Descriptions.Item label="Identity code" span={2}>
          {data.identity_code}
        </Descriptions.Item>

        <Descriptions.Item label="Container type" span={2}>
          {data.type.name}
        </Descriptions.Item>

        <Descriptions.Item label="Country" span={2}>
          {data.country}
        </Descriptions.Item>

        <Descriptions.Item label="Current status" span={2}>
          {data.status}
        </Descriptions.Item>

        <Descriptions.Item label="Number of cycles" span={2}>
          {data.number_of_cycles}
        </Descriptions.Item>

        <Descriptions.Item label="Manufacturing date" span={2}>
          {localizedDate(data.manufacturing_date)}
        </Descriptions.Item>

        <Descriptions.Item label="Manufacturing company" span={2}>
          {data.manufacturing_company}
        </Descriptions.Item>
      </Descriptions>

      <Row
        align="middle"
        justify="space-between"
        style={{ margin: '20px 0 10px' }}
      >
        <Col>
          <Title level={4}>
            Event
          </Title>
        </Col>
      </Row>

      <Table
        bordered
        className="eventsTableSelenium"
        columns={tableColumns}
        dataSource={containerEvents.data || []}
        loading={containerEvents.isLoading}
        locale={{
          emptyText: <Empty description={(containerEvents.error && containerEvents.error.message) || 'No events'} />,
        }}
        pagination={false}
        rowKey="id"
      />
    </div>
  )
}

ContainerDetailsPage.propTypes = {
  ...routePropTypes,
}

export default ContainerDetailsPage
